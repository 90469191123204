.select2-search--dropdown > input {
    margin-left: 0;
}

.select2-container .select2-results__option[aria-disabled=true] {
    display: none;
}

.select2-selection__choice__remove {
    float: right;
    margin-left: 5px;
    padding-left: 4px;
    padding-right: 4px;
}

.select2-selection__choice {
    text-wrap: balance;
}
