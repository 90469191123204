.btn {
    border: 1px solid #2d8c8c;
    border-radius: 50px;
    padding: 5px 30px;
    float: right;
    background: none;
    color: #2d8c8c;
}

.btn:hover {
    background-color: #2d8c8c;
    color: #fff;
}

.btn.none {
    float: none;
}

.btn.btn-noBorder {
    border: 0;
    padding: 0;
    float: none;
}

.btn.btn-noBorder:hover {
    transition: all 0s ease;
    background: none;
    color: #cacaca;
}

.btn.btn-noBorder:focus {
    box-shadow: none;
}

.btn.btn-tiny {
    font-size: 12px;
}

.btn .add, .btn .remove {
    border: 1px solid #2d8c8c;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 17px;
    font-weight: 400;
    font-size: 20px;
    position: relative;
    margin-right: 3px;
    display: inline-block;
    top: 1px;
}

.btn .remove {
    line-height: 15px;
    text-indent: -1px;
    top: 0;
}

.section .btn.right {
    margin-right: 20px;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: #2d8c8c;
}

.nav-link.toggleLabel:not(.active) {
    color: #2d8c8c;
}

.nav-pills .nav-link a {
    text-decoration: none;
    width: calc(100% - 25px);
    display: inline-block;
}

.nav-pills .nav-link.active a {
    color: #fff;
    text-decoration: none;
}

.nav-pills .nav-link {
    font-weight: 500;
    padding: 12px 10px;
    line-height: 16px;
}

.nav.nav-tabs {
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 1px solid #cacaca;
}

.nav-tabs .nav-link {
    border-color: #ddd #ddd #cacaca;
    margin-right: 5px;
    color: #2d8c8c;
}

.nav-tabs .nav-link:hover {
    border-color: #cacaca;
}

.nav-tabs .nav-link.active {
    border: 1px solid #cacaca;
    border-bottom: 1px solid #f2f2f2;
    background-color: #f2f2f2;
    color: #656565;
}


.table>:not(caption)>*>*{
    color: currentColor !important
}

.table {
    --bs-table-bg: undefined;
}

.divTableBody input:focus {
    border: 0;
    border-bottom: 1px solid #cacaca;
}

.originState >.col-md-12{
    
    padding-left: 15px;
}